<template>
  <div class="content-wrapper">
    <bo-page-title>
        <template slot="additionalCta">
            <b-button @click="doAdd()" class="btn bg-blue-400 btn-labeled btn-labeled-left ml-1">
              <b><i class="icon-plus-circle2"></i></b>
                Tambah Ketersediaan Kamar
            </b-button>
        </template>
    </bo-page-title>
    <div class="content pt-0">
      <b-card v-if="isList" no-body>
        <b-card-header>
          <b-row>
            <div class="col-md-auto">
                <div class="form-group mb-0">
                    <button @click="doRefreshData" data-toggle="modal" data-target="#FilterField" data-popup="tooltip" class="btn btn-light" title="Muat Ulang" v-b-tooltip.hover ><i class="icon-spinner11"></i></button>
                </div>
            </div>
            <b-col lg="8">
              <b-form-row>
                <b-col lg="4">
                  <b-form-group>
                    <v-select placeholder=" -- Pilih Kode Kelas -- " @input="doFill" v-model="filter.kode" :options="mRuanganRanap" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                  </b-form-group>
                </b-col>
                <b-col lg="4">                  
                  <b-form-group>
                    <v-select placeholder=" -- Pilih Limit Data -- " @input="doFill" v-model="filter.limit" :options="tampilkanData" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                  </b-form-group>
                </b-col>
                <b-col lg="4">                  
                  <b-form-input type="text" class="form-control"  @input="doFill" v-model="filter.nama_ruangan" placeholder="Nama Ruangan"/>
                </b-col>
              </b-form-row>
            </b-col>
            <b-col lg="3">            
                <b-button
                    class="ml-1 d-inline-flex align-items-center"
                    variant="outline-success"
                    id="resetBtn"
                    @click="doReset()"
                >Reset</b-button>
            </b-col>
          </b-row>

        </b-card-header>
        <b-card-body class="p-0">
            <div class="table-responsive">
                <table class="table table-striped">
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Kode Kelas</th>
                        <th>Nama Kelas</th>
                        <th>Tersedia</th>
                        <th>Tersedia Pria</th>
                        <th>Tersedia Wanita</th>
                        <th>Kode Ruang</th>
                        <th>Kode PPK</th>
                        <th>Nama Ruang</th>
                        <th>Kapasitas</th>
                        <th>Terakhir diupdate</th>
                        <th>Aksi</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(v,k) in data" :key="k">
                        <td>{{k+1}}</td>
                        <td>{{v.kodekelas}}</td>
                        <td>{{v.namakelas}}</td>
                        <td>{{v.tersedia}}</td>
                        <td>{{v.tersediapria}}</td>
                        <td>{{v.tersediawanita}}</td>
                        <td>{{v.koderuang}}</td>
                        <td>{{v.kodeppk}}</td>
                        <td>{{v.namaruang}}</td>
                        <td>{{v.kapasitas}}</td>
                        <td>{{v.lastupdate}}</td>
                        <td>
                            <a href="javascript:;" class="list-icons-item"
                            v-if="moduleRole('edit')"
                            @click="doEdit(v)"
                            data-toggle="tooltip" data-placement="top" title="View"><i
                                class="icon-pencil5"></i></a>

                            <a href="javascript:;" class="list-icons-item"
                            v-if="moduleRole('delete')"
                            @click="doDelete(v)"
                            data-toggle="tooltip" data-placement="top" title="Delete"><i
                                class="icon-bin"></i></a>

                            <a href="javascript:;" class="list-icons-item"
                            @click="openDetailData(v)"
                            data-toggle="tooltip" data-placement="top" title="Lihat Detail"><i
                                class="icon-eye"></i></a>
                        </td>
                            
                    </tr>
                    </tbody>
                    <tbody v-if="data.length == 0">
                    <tr>
                        <td colspan="99">
                        <div class="text-center">
                            <h4 align="center"><span v-if="filter.status || filter.search || filter.startDate || filter.endDate">Hasil pencarian tidak ditemukan</span>
                            <span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
                        </div>
                        </td>
                    </tr>
                    </tbody>
                    
                    <tbody v-if="!data">
                    <tr>
                        <td colspan="99">
                        <div class="text-center">
                            <div class="loader-circle"></div>
                        </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </b-card-body>
      </b-card>
    </div>



    <b-modal 
        v-model="openDetail"
        size="lg"
        :title="'Detail Ketersediaan Ruangan'"
        no-close-on-backdrop
        ok-only
        ok-title="Tutup"
    >
        
        <div class="table-responsive">
            <table class="table table-bordered">
                <tbody>

                <tr>
                    <td>
                        <div class="result_tab">
                            <label for="">Kode Ruangan</label>
                            <p>{{row.koderuang||"-"}}</p>
                        </div>
                    </td>
                    <td>
                        <div class="result_tab">
                            <label for="">Kode Kelas</label>
                            <p>{{getConfigDynamic(mRuanganRanap,row.kodekelas)||"-"}}</p>
                        </div>
                    </td>
                </tr>
                
                <tr>
                    <td>
                        <div class="result_tab">
                            <label for="">Nama Ruangan</label>
                            <p>{{row.namaruang||"-"}}</p>
                        </div>
                    </td>
                    <td>
                        <div class="result_tab">
                            <label for="">Kapasitas</label>
                            <p>{{row.kapasitas}}</p>
                        </div>
                    </td>
                </tr>
                
                <tr>
                    <td>
                        <div class="result_tab">
                            <label for="">Tersedia</label>
                            <p>{{row.tersedia||"-"}}</p>
                        </div>
                    </td>
                    <td>
                        <div class="result_tab">
                            <label for="">Tersedia Pria & Wanita</label>
                            <p>{{row.tersediapriawanita}}</p>
                        </div>
                    </td>
                </tr>
                
                <tr>
                    <td>
                        <div class="result_tab">
                            <label for="">Tersedia Pria</label>
                            <p>{{row.tersediapria}}</p>
                        </div>
                    </td>
                    <td>
                        <div class="result_tab">
                            <label for="">Tersedia Wanita</label>
                            <p>{{row.tersediawanita||"-"}}</p>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <div class="table-responsive">
            <table class="table table-striped">
            <thead>
            <tr>
                <th>#</th>
                <th>Kode Kelas</th>
                <th>Nama Kelas</th>
                <th>Tersedia</th>
                <th>Tersedia Pria</th>
                <th>Tersedia Wanita</th>
                <th>Tersedia Pria Wanita</th>
                
                <th>Kode Ruang</th>
                <th>Nama Ruang</th>
                <th>Kapasitas</th>
                <th>Terakhir diupdate</th>
                <th>Diupdate oleh</th>            
            </tr>
            </thead>
            <tr v-for="(v,k) in (logData||[])" :key="k">
                <td>{{k+1}}</td>
                <td>{{v.lhkr_kodekelas}}</td>
                <td>{{getConfigDynamic(mRuanganRanap,v.lhkr_kodekelas)}}</td>
                <td>{{v.lhkr_tersedia}}</td>
                <td>{{v.lhkr_tersediapria}}</td>
                <td>{{v.lhkr_tersediawanita}}</td>
                <td>{{v.lhkr_tersediapriawanita}}</td>

                <td>{{v.lhkr_koderuang}}</td>
                <td>{{v.lhkr_namaruang}}</td>
                <td>{{v.lhkr_kapasitas}}</td>
                <td>{{v.lhkr_created_date | moment("DD MMMM YYYY, HH:mm")}}</td>
                <td>{{v.lhkr_created_by}}</td>
            </tr>
            <tr v-if="!(logData||[]).length">
                <td colspan="99" class="text-center">Tidak ada data</td>
            </tr>
            </table>
        </div>
    </b-modal>

    <validation-observer ref="VFormKetersediaanKamar">    
        <b-modal 
            v-model="openRuanganRanap"
            size="lg"
            :title="row.type == 'add' ? 'Tambah Ketersediaan Ruangan':'Edit Ketersediaan Ruangan'"
            no-close-on-backdrop
            @ok.prevent="sendData"
        >   <!--
            "kodekelas" => "required",
            "koderuang" => "required",
            "namaruang" => "required",
            "kapasitas" => "required",
            "tersedia" => "required",
            "tersediapria" => "required",
            "tersediawanita" => "required",
            "tersediapriawanita" => "required",
            -->
            <div class="row">
                <div class="col-md-12">
                    <b-row>
                        <b-col lg="6">
                            <b-form-group>
                            <label>Kode Ruangan<span class="text-danger mr5">*</span></label>
                            <b-form-input type="text" class="form-control" v-model="row.koderuang" placeholder="Kode Ruangan"/>
                            <VValidate 
                                name="Kode Ruangan" 
                                v-model="row.koderuang" 
                                :rules="toValidate(mrValidation.koderuang)"
                            />
                            </b-form-group>
                        </b-col>
                        
                        <b-col lg="6">
                            <b-form-group>
                            <label>Kode Kelas<span class="text-danger mr5">*</span></label>
                            <v-select placeholder=" -- Pilih Kode Kelas -- " v-model="row.kodekelas" :options="mRuanganRanap" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                            <VValidate 
                                name="Kode Kelas" 
                                v-model="row.kodekelas" 
                                :rules="toValidate(mrValidation.kodekelas)"
                            />
                            </b-form-group>
                        </b-col>

                        
                        <b-col lg="6">
                            <b-form-group>
                            <label>Nama Ruangan<span class="text-danger mr5">*</span></label>
                            <b-form-input type="text" class="form-control" v-model="row.namaruang" placeholder="Nama Ruangan"/>
                            <VValidate 
                                name="Nama Ruangan" 
                                v-model="row.namaruang" 
                                :rules="toValidate(mrValidation.namaruang)"
                            />
                            </b-form-group>
                        </b-col>

                        <b-col lg="6">
                            <b-form-group>
                            <label>Kapasitas<span class="text-danger mr5">*</span></label>
                            <b-form-input :formatter="numberOnly" type="text" class="form-control" v-model="row.kapasitas" placeholder="Kapasitas"/>
                            <VValidate 
                                name="Kapasitas" 
                                v-model="row.kapasitas" 
                                :rules="toValidate(mrValidation.kapasitas)"
                            />
                            </b-form-group>
                        </b-col>
                        
                        <b-col lg="6">
                            <b-form-group>
                            <label>Tersedia<span class="text-danger mr5">*</span></label>
                            <b-form-input :formatter="numberOnly" type="text" class="form-control" v-model="row.tersedia" placeholder="Tersedia"/>
                            <VValidate 
                                name="Tersedia" 
                                v-model="row.tersedia" 
                                :rules="toValidate(mrValidation.tersedia)"
                            />
                            </b-form-group>
                        </b-col>
                        
                        <b-col lg="6">
                            <b-form-group>
                            <label>Tersedia Pria Wanita<span class="text-danger mr5">*</span></label>
                            <b-form-input :formatter="numberOnly" type="text" class="form-control" v-model="row.tersediapriawanita" placeholder="Tersedia Pria Wanita"/>
                            <VValidate 
                                name="Tersedia Pria Wanita" 
                                v-model="row.tersediapriawanita" 
                                :rules="toValidate(mrValidation.tersediapriawanita)"
                            />
                            </b-form-group>
                        </b-col>

                        
                        <b-col lg="6">
                            <b-form-group>
                            <label>Tersedia Pria<span class="text-danger mr5">*</span></label>
                            <b-form-input :formatter="numberOnly" type="text" class="form-control" v-model="row.tersediapria" placeholder="Tersedia Pria"/>
                            <VValidate 
                                name="Tersedia Pria" 
                                v-model="row.tersediapria" 
                                :rules="toValidate(mrValidation.tersediapria)"
                            />
                            </b-form-group>
                        </b-col>

                        
                        <b-col lg="6">
                            <b-form-group>
                            <label>Tersedia Wanita<span class="text-danger mr5">*</span></label>
                            <b-form-input :formatter="numberOnly" type="text" class="form-control" v-model="row.tersediawanita" placeholder="Tersedia Wanita"/>
                            <VValidate 
                                name="Tersedia Wanita" 
                                v-model="row.tersediawanita" 
                                :rules="toValidate(mrValidation.tersediawanita)"
                            />
                            </b-form-group>
                        </b-col>
                        

                    </b-row>
                </div>
            </div>
        </b-modal>
    </validation-observer>

  </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
const _ = global._
import Gen from '@/libs/Gen.js'

export default {
  extends: GlobalVue,
  data() {
    return {
      idKey:'mpo_id',
      statusKey:'mpo_is_active',
      mRuanganRanap: [],
      tampilkanData: [
        {
            text: "25",
            value: "25",
        },
        {
            text: "50",
            value: "50",
        },
        {
            text: "100",
            value: "100",
        },
      ],
      openRuanganRanap: false,
      openDetail: false,
      logData: [],
      data: []
    }
  },
  computed:{
    passToSub(){
      return _.assign(this.passToSubComp||{})
    }
  },
  mounted(){
    this.apiGet()
    if(this.$route.params.pageSlug){
      this.validateModuleRoleCrud()
    }
  },
  methods: {
    getConfigDynamic(master,value){
        let text = ''
        if(value){
            let index = (master||[]).findIndex(x => x.value == value)
            if(index !== -1){
                text = master[index]['text']
            }
        }
        return text
    },
    doRefreshData(){
        this.apiGet(true)
        window.scrollTo(0,0)
    },
    doFill(){
      this.doFilter()
    },
    toValidate(val) {
        return {
            ...val
        }
    },

    sendData(){
        this.$refs['VFormKetersediaanKamar'].validate().then(success => {
            if(success){
                this.$swal({
                    icon: 'warning',
                    title: 'Apakah Anda Yakin akan menyimpan data ini?',
                    showCancelButton: true,
                    confirmButtonText: 'Ya',
                    cancelButtonText: 'Tidak, kembali'
                }).then(result => {
                    if (result.value) {
                        this.loadingOverlay = true
                        let data = this.row
                        Gen.apiRest(
                        "/do/" + 'BPJSKetersediaanKamar', {
                            data: data
                        },
                        "POST"
                        ).then(res => {
                        this.loadingOverlay = false
                        let resp = res.data
                        resp.statusType = 200
                        this.$swal({
                            title: resp.message,
                            icon: resp.status,
                            confirmButtonText: 'Ok',
                            allowOutsideClick: false,
                            allowEscapeKey: false
                        }).then(result => {
                            if (result.value) {
                                this.openRuanganRanap = false
                                this.apiGet()
                            }
                        })
                        }).catch(err => {
                            this.loadingOverlay = false
                            if (err) {
                                err.statusType = err.status
                                err.status = "error"
                                err.title = err.response?.data?.title
                                err.message = err.response?.data?.message
                                err.messageError = err.message
                            }
                            this.doSetAlertForm(err)
                        })
                    }
                })
            }
        })
    },
    doAdd(){
        this.row = {
            kodekelas : null
        }
        this.row.type = "add"
        this.openRuanganRanap = true
    },
    doEdit(v){
    //   this.$router.push({name: this.$route.name, params: {pageSlug: data.item[idKey]}}).catch(()=>{})
        this.row = v
        this.row.type = "update"
        this.openRuanganRanap = true
    },
    
    openDetailData(v){
        this.row = v
        this.loadingOverlay = true
        Gen.apiRest(
            "/do/" + 'BPJSKetersediaanKamar', {
                data: {
                    kode : v.koderuang, 
                    type : "view-detail"
                }
            },
            "POST"
        ).then(res => {
            this.loadingOverlay = false
            this.logData = res.data.logData
            this.openDetail = true
        })
    },

    doDelete(v){
       this.$swal({
            title: 'Hapus Data Ketersediaan Kamar?',
            text: "Anda tidak akan dapat memulihkan data ini!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            confirmButtonText: 'Ya, Hapus!',
            cancelButtonText: 'Kembali',
            customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
        }).then(result => {
            if (result.value) {
                this.loadingOverlay = true
                let data = v
                data.type = "delete"
                Gen.apiRest(
                "/do/" + 'BPJSKetersediaanKamar', {
                    data: data
                },
                "POST"
                ).then(res => {
                this.loadingOverlay = false
                let resp = res.data
                resp.statusType = 200
                this.$swal({
                    title: resp.message,
                    icon: resp.status,
                    confirmButtonText: 'Ok',
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then(result => {
                    if (result.value) {
                        this.openRuanganRanap = false
                        this.apiGet()
                    }
                })
                }).catch(err => {
                    this.loadingOverlay = false
                    if (err) {
                        err.statusType = err.status
                        err.status = "error"
                        err.title = err.response?.data?.title
                        err.message = err.response?.data?.message
                        err.messageError = err.message
                    }
                    this.doSetAlertForm(err)
                })
            }
        })
    }
  },
  watch:{
    $route(){
      this.apiGet()
    },
    '$route.params.pageSlug':function(){
      this.$set(this,'filter',{})
    },
    openRuanganRanap(v){
        if(!v){
            this.apiGet()
        }
    }
  }
}
</script>